import { FC, RefObject, useEffect, useRef, useState } from 'react'
import isNil from 'lodash/isNil'
import jsLogger from 'js-logger'
import { captureThumb } from 'shared/components/recorder/getVideoInfo'
import * as mediapipe from '@mediapipe/tasks-vision'
import { sendUserScreenshot } from 'controllers/main'

interface Props {
  videoRef: RefObject<HTMLVideoElement>
  onComplete: () => void
  interactionId: string
  sayLookAtCamera: () => void
  cameraOn: boolean
}

const DURATION = 10000
const SCREENSHOTS_PERIOD = 500
const INITIAL_DELAY = 3000
const INITIAL_AMOUNT = 10

const CapturePhoto: FC<Props> = ({
  videoRef,
  onComplete,
  interactionId,
  sayLookAtCamera,
  cameraOn
}) => {
  const firstScreenshotTakenRef = useRef<Boolean>(false)
  const endTimeRef = useRef<number>(Date.now() + DURATION)
  const facedetectorRef = useRef<mediapipe.FaceDetector | null>(null)
  const [modelLoaded, setModelLoaded] = useState<boolean | null>(null)
  const triesLeft = useRef(INITIAL_AMOUNT)

  useEffect(() => {
    const run = async () => {
      try {
        await loadModel(3)
      } catch (error) {
        jsLogger.error('Error in loading model', { error })
      }
    }
    run()
  }, [])

  useEffect(() => {
    if (cameraOn && modelLoaded !== null) {
      endTimeRef.current = Date.now() + DURATION
      setTimeout(takeScreenshot, INITIAL_DELAY)
    }
  }, [cameraOn, modelLoaded])

  const loadModel = async (tries: number) => {
    jsLogger.log('loading model start', { triesLeft: tries })
    const startTime = Date.now()
    try {
      // throw new Error('test error')
      const vision = await mediapipe.FilesetResolver.forVisionTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm'
      )
      facedetectorRef.current = await mediapipe.FaceDetector.createFromOptions(
        vision,
        {
          baseOptions: {
            modelAssetPath: '/models/blaze_face_short_range.tflite'
          },
          runningMode: 'IMAGE',
          minDetectionConfidence: 0.9
        }
      )
      jsLogger.log('loading model end', { latency: Date.now() - startTime })
      setModelLoaded(true)
    } catch (error) {
      jsLogger.error('Error loading model', { error })
      if (tries > 0) {
        jsLogger.log(`Retrying model load, attempts left: ${tries - 1}`)
        await loadModel(tries - 1)
      } else {
        jsLogger.error('Failed to load model after multiple attempts')
        setModelLoaded(false)
        throw new Error('Failed to load model after multiple attempts')
      }
    }
  }

  const canSchedule = () => {
    return Date.now() < endTimeRef.current
  }

  const scheduleNextCall = () => {
    jsLogger.log('scheduleNextCall: Checking if next call should be scheduled')
    if (canSchedule()) {
      jsLogger.log('scheduleNextCall: Scheduling next screenshot call')
      setTimeout(takeScreenshot, SCREENSHOTS_PERIOD)
    } else {
      jsLogger.log(
        'scheduleNextCall: End time reached, not scheduling further calls'
      )
    }
  }

  const rekognizeFace = async (b: Blob) => {
    jsLogger.log('rekognizeFace start')
    const startType = Date.now()
    const fd = facedetectorRef.current
    try {
      if (fd) {
        const imageBitmap = await createImageBitmap(b)
        const res = fd.detect(imageBitmap)
        jsLogger.log('detections', { res })
        const latency = Date.now() - startType
        jsLogger.log('face detection latency', { latency })
        return res.detections.length > 0
      } else {
        console.error('image recognition is not initialized')
        return null
      }
    } catch (e) {
      jsLogger.error('rekognizeFace error', { e })
      return null
    }
  }

  const takeScreenshot = async () => {
    jsLogger.log('takeScreenshot call start', {
      cameraInitialized: !isNil(videoRef.current?.srcObject)
    })
    if (!videoRef.current?.srcObject) {
      jsLogger.log('takeScreenshot: no video element, exiting the loop')
      scheduleNextCall()
      return
    } else {
      jsLogger.log(
        'takeScreenshot: camera video ref and onScreenshot function exist'
      )
      try {
        const mediaType = 'jpeg'
        const quality = 0.8
        const maxSize = 720
        // const maxSize = !firstScreenshotTakenRef.current ? 512 : 720
        if (videoRef.current.videoHeight === 0) {
          jsLogger.log(
            'takeScreenshot: video height is 0, will try a bit later'
          )
          scheduleNextCall()
          return
        }

        const screenshotBlob = await captureThumb(
          videoRef.current,
          mediaType,
          quality,
          maxSize
        )
        if (screenshotBlob) {
          jsLogger.log('screenshot blob created')
          const hasFace = await rekognizeFace(screenshotBlob)
          jsLogger.log('face recognition result', { hasFace })
          if (hasFace) {
            jsLogger.log('face detected, sending screenshot')
            await sendUserScreenshot(interactionId, screenshotBlob, mediaType)
            onComplete()
          } else if (hasFace === null) {
            jsLogger.log(
              'face recognition returned null, decrementing triesLeft'
            )
            triesLeft.current = triesLeft.current - 1
            jsLogger.log('Tries left after decrement:', {
              triesLeft: triesLeft.current
            })
            if (triesLeft.current <= 0) {
              jsLogger.log('No tries left, sending the screenshot')
              await sendUserScreenshot(interactionId, screenshotBlob, mediaType)
              onComplete()
            } else {
              jsLogger.log('Tries left, scheduling next call')
              scheduleNextCall()
            }
          } else {
            jsLogger.log('no face detected', {
              firstScreenshotTaken: firstScreenshotTakenRef.current
            })
            jsLogger.log('Checking if first screenshot has been taken')
            if (!firstScreenshotTakenRef.current) {
              jsLogger.log('First screenshot not taken, decrementing triesLeft')
              triesLeft.current = triesLeft.current - 1
              jsLogger.log('Tries left after decrement:', {
                triesLeft: triesLeft.current
              })
              if (triesLeft.current <= 0) {
                jsLogger.log('No tries left, prompting user to look at camera')
                sayLookAtCamera()
                firstScreenshotTakenRef.current = true
                jsLogger.log('First screenshot flag set to true')
              } else {
                jsLogger.log('Tries left, scheduling next call')
              }
            }

            if (!canSchedule()) {
              jsLogger.log('cannot schedule, sending screenshot anyway')
              await sendUserScreenshot(interactionId, screenshotBlob, mediaType)
              onComplete()
            } else {
              jsLogger.log('scheduling next call')
              scheduleNextCall()
            }
          }
        } else {
          jsLogger.error('screenshot_capture_error')
          scheduleNextCall()
        }
      } catch (e) {
        jsLogger.error('capturing screnshot error', { e })
        scheduleNextCall()
      }
    }
  }

  return null
}

export default CapturePhoto
