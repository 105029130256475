import { FC } from 'react'
import PageContainer from 'shared/components/PageContainer'
type Props = {
  imageUrl?: string
}
const FinalPage: FC<Props> = ({ imageUrl }) => {
  return (
    <PageContainer>
      <div className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative bg-gray-700'>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt='Avatar'
            className='absolute w-full h-full object-cover left-0 top-0 right-0 bottom-0'
          />
        ) : (
          <div className='w-full h-full flex justify-center items-center'>
            <span>Thank you</span>
          </div>
        )}
      </div>
    </PageContainer>
  )
}

export default FinalPage
