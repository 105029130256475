import jsLogger from 'js-logger'

const maxRetryCount = 3
const maxDelaySec = 4

export const fetchWithRetries = async (
  url: string,
  // eslint-disable-next-line no-undef
  options: RequestInit,
  retries: number = 1
): Promise<Response> => {
  try {
    jsLogger.log(`Attempting fetch for ${url}, try ${retries}`)
    const response = await fetch(url, options)
    jsLogger.log(`Fetch successful for ${url} on try ${retries}`)
    return response
  } catch (err) {
    jsLogger.error(`Fetch failed for ${url} on try ${retries}`, err)
    if (retries <= maxRetryCount) {
      const delay =
        Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000

      jsLogger.info(`Retrying fetch for ${url} after delay of ${delay}ms`)
      await new Promise(resolve => setTimeout(resolve, delay))

      return fetchWithRetries(url, options, retries + 1)
    } else {
      jsLogger.error(`Max retries exceeded for ${url}. Error: ${err}`)
      throw new Error(`Max retries exceeded. error: ${err}`)
    }
  }
}

export default fetchWithRetries
