import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import PACKAGE from '../../package.json'
import jsLogger from 'js-logger'
import * as Proto from 'types/proto'
import { Phrase } from '@facesignai/api'
import config from 'src/config'

const getParentUrl = () => {
  const isInIframe = window.parent !== window
  let parentUrl = ''

  if (isInIframe) {
    parentUrl = document.referrer
  }

  return parentUrl
}

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Parent-Href': getParentUrl()
}

// const mimeToExtMap = {
//   'video/mp4': 'mp4',
//   'video/webm': 'webm',
//   'video/ogg': 'ogg',
//   'video/quicktime': 'mov',
//   'video/x-matroska': 'mkv',
//   'video/x-msvideo': 'avi'
// }

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  } else {
    return (
      get(navigator, 'userLanguage') ||
      get(navigator, 'language') ||
      get(navigator, 'browserLanguage') ||
      'en'
    )
  }
}

export const conversationStart = async (clientSecret: string) => {
  try {
    const url = `${config.backendUrl}/fsclient/start`
    const lang = getNavigatorLanguage().slice(0, 2).toLowerCase()
    const params: Proto.SessionStartRequest = {
      version: PACKAGE.version,
      lang
    }
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      body: JSON.stringify(params)
    })
    jsLogger.log('conversationStart call start', { url, headers })
    const res = (await req.json()) as Proto.SessionStartResponse | Proto.Error
    jsLogger.log('conversationStart call res', res)
    return res
  } catch (e) {
    jsLogger.error('interactionStart error', e)
    // Sentry.captureException(e)
    return { error: get(e, 'message', 'unknown') } as Proto.Error
  }
}

export const submitReply = async (
  clientSecret: string,
  reply: string,
  signal?: AbortSignal
) => {
  try {
    // const pageUrl = new URL(window.location.href)
    const url = `${config.backendUrl}/fsclient/submit_reply`
    const params: Proto.SubmitReplyRequest = {
      reply
    }
    const startTime = Date.now()
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      signal,
      priority: 'high',
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('create reply latency', Date.now() - startTime)
    jsLogger.log('submitReply res', res)
    return res as Proto.SubmitReplyResponse | Proto.Error
  } catch (e) {
    if (get(e, 'name') === 'AbortError') {
      jsLogger.info('generateResponse request has been aborted')
      return { error: 'The request has been aborted' } as Proto.Error
    } else {
      jsLogger.warn('generateResponse error', e)
      return { error: get(e, 'message', 'unknown') } as Proto.Error
    }
  }
}

export const applyResponse = async (
  clientSecret: string,
  q: Phrase,
  latencies?: { dgLatency: number; grLatency: number }
) => {
  try {
    const url = `${config.backendUrl}/fsclient/apply_response`
    const params = {
      q,
      latencies
    }
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      priority: 'low',
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('applyResponse res', res)
    return res
  } catch (e) {
    jsLogger.warn('applyResponse error', e)
    // Sentry.captureException(e)
    return
  }
}

export const sendAvatarLatency = async (
  clientSecret: string,
  q: Phrase,
  latency: number
) => {
  try {
    const url = `${config.backendUrl}/fsclient/avatar_latency`
    const params = {
      phraseId: q.id,
      latency
    }
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('sendAvatarLatency res', res)
    return res
  } catch (e) {
    jsLogger.warn('sendAvatarLatency error', e)
    // Sentry.captureException(e)
    return
  }
}

export const checkInterrupt = async (clientSecret: string, reply: string) => {
  try {
    const url = `${config.backendUrl}/fsClient/checkInterrupt`
    const params = {
      reply
    }
    const startTime = Date.now()
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('checkInterrupt latency', Date.now() - startTime, reply)
    jsLogger.log('checkInterrupt res', res)
    const interrupt = get(res, 'interrupt', false)
    return interrupt
  } catch (e) {
    jsLogger.warn('checkInterrupt error', e)
    // Sentry.captureException(e)
    return false
  }
}

export const sendUserScreenshot = async (
  clientSecret: string,
  screenshotBlob: Blob,
  mediaType: string
) => {
  try {
    jsLogger.log('sendUserScreenshot', screenshotBlob.size)
    const url = `${config.backendUrl}/fsclient/screenshot?media=${mediaType}`
    const req = await fetch(url, {
      method: 'POST',
      body: screenshotBlob,
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`,
        'Content-Type': 'application/octet-stream'
      }
    })
    const data = await req.json()

    jsLogger.log('sendUserScreenshot response', data)
    return data.success
  } catch (e) {
    jsLogger.error('could not sent screenshot', { error: e })
    return false
  }
}

export const dbSendUserEmail = async (clientSecret: string, email: string) => {
  try {
    jsLogger.log('sendUserEmail', { email })
    const startTime = Date.now()
    const url = `${config.backendUrl}/fsclient/submit_email`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      }
    })
    const data = await req.json()
    const latency = Date.now() - startTime
    jsLogger.log('sendUserEmail response', { data, latency })
    return data?.success
  } catch (e) {
    jsLogger.error('could not send email', { error: e })
    return false
  }
}

export const dbSkipEmail = async (clientSecret: string) => {
  try {
    jsLogger.log('dbSkipEmail')
    const startTime = Date.now()
    const url = `${config.backendUrl}/fsclient/skip_email_input`
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      }
    })
    const data = await req.json()
    const latency = Date.now() - startTime
    jsLogger.log('dbSkipEmail response', { data, latency })
    return data?.success
  } catch (e) {
    jsLogger.error('dbSkipEmail', { error: e })
    return false
  }
}

export const generateId = (): string => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0')
  const id = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`
  return id
}

export const sendError = async (
  event: Sentry.Event,
  hint: Sentry.EventHint
) => {
  const url = `${config.backendUrl}/fsclient/error`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ event, hint }),
    priority: 'low',
    headers
  })
  const data = await req.json()

  jsLogger.log('sendError response', data)
  return data
}

export const checkIsWebMSupported = () => {
  // Create a dummy video element
  var video = document.createElement('video')

  // Check if the browser supports the WebM video format
  return video.canPlayType('video/webm') !== ''
}

export const sessionSetLang = async (clientSecret: string, lang: string) => {
  try {
    jsLogger.log('interactionSetLang', { lang })
    const url = `${config.backendUrl}/fsclient/setLang`
    const req = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: `Bearer ${clientSecret}`
      },
      priority: 'low',
      body: JSON.stringify({
        lang
      })
    })
    const data = await req.json()
    jsLogger.log('interactionSetLang response', data)
    return null
  } catch (e) {
    jsLogger.error('interactionSetLang error', { error: e })
    return null
  }
}
