import { FC, useState } from 'react'
import jsLogger from 'js-logger'
import { getString } from 'controllers/localization'

interface EmailInputProps {
  onNotNowClick: () => void
  onConfirmClick: (email: string) => void
  locale: string
}

const EmailInput: FC<EmailInputProps> = ({
  onNotNowClick,
  onConfirmClick,
  locale
}) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | null>(null)

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setError(null) // Clear error when user starts typing
  }

  const onConfirm = () => {
    jsLogger.log('onConfirm: Email confirmation initiated', { email })
    const emailIsValid = validateEmail(email)
    if (emailIsValid) {
      jsLogger.log('onConfirm: Email is valid', { email })
      onConfirmClick(email)
    } else {
      jsLogger.warn('onConfirm: Invalid email, displaying error', { email })
      setError('email_invalid')
    }
  }

  return (
    <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50'>
      <div className='bg-white rounded-xl w-80'>
        <h2 className='text-lg font-semibold mb-1 text-center pt-4'>
          Enter Email
        </h2>
        <p className='text-sm mb-4 text-center px-6'>
          Input your email address below to receive a code.
        </p>

        <div className='px-6'>
          <input
            type='email'
            value={email}
            onChange={handleEmailChange}
            placeholder='email@example.com'
            className={`w-full px-2 py-0.5 border border-gray-300 rounded-md mb-1`}
            autoFocus
          />
          {error && (
            <p className='text-red-500 text-sm mb-4'>
              {getString(error, locale)}
            </p>
          )}
        </div>
        <div className='flex justify-between border-t'>
          <button
            className='text-blue-500 w-full border-r py-2'
            onClick={onNotNowClick}
          >
            Not Now
          </button>
          <button
            className='text-blue-500 w-full py-2 font-semibold'
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmailInput
