import { FC, useRef, useState, useEffect, useMemo } from 'react'
import get from 'lodash/get'
import trim from 'lodash/trim'
import map from 'lodash/map'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

import {
  applyResponse,
  checkInterrupt,
  dbSendUserEmail,
  dbSkipEmail,
  sendAvatarLatency,
  submitReply
} from 'controllers/main'
import HeyGenConnect, {
  IHeyGenConnect
} from 'shared/components/HeyGenConnectLiveKit'
import AzureConnect from 'shared/components/AzureConnect'
import CustomAvatarConnect from 'shared/components/CustomAvatarConnect'
import Recorder, { IRecorder } from 'shared/components/Recorder'
import RecorderDummy from 'shared/components/RecorderDummy'
import PageContainer from 'shared/components/PageContainer'
import { ControlT, Phrase } from 'types/internal'
import { PermissionErrorType } from 'types/proto'
import EndConversation from 'shared/components/EndConversation'
import ControlPanel, {
  IControlPanel,
  Step
} from 'shared/components/ControlPanel'
import FixPermission from 'shared/components/FixPermission'
import PermissionsHandler from 'shared/components/PermissionsHandler'
import PACKAGE from '../../package.json'
import jsLogger from 'js-logger'
import { getBackClient } from 'controllers/back'
import { DBT } from 'types/internal'
import { getString } from 'controllers/localization'
import CapturePhoto from 'pages/conversation/CapturePhoto'
import EmailInput from 'pages/conversation/EmailInput'
import * as Proto from 'types/proto'

const SILENCE_DURATION = 10000

type Props = {
  clientSecret: string
  sessionInfo: Proto.SessionStartResponse
  onConversationFinished: () => void
  isTest?: boolean
  lang: string
  onChangeLang: (lang: string) => void
  handleChunk: (
    videoBlob: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => void

  // interactionId: string
  // onConversationFinished: () => void

  // initVersion: 0 | 1
  // isTest: boolean
  // setDuration: (v: number) => void
  // azureKey?: { token: string; region: string }
  // heygenKey?: string
  // steps: TwoFactorT.StepT[]
  // setSteps: (steps: TwoFactorT.StepT[]) => void
  // setRecapData: (data: TwoFactorT.RecapT | null) => void
  // avatar: TwoFactorT.AvatarT
  // interruptionMode: DBT.INTERRUPT
  // lang: string
  // availableLanguages: DBT.LangT[]
  // onChangeLang: (lang: string) => void
  // transcriber: DBT.TRANSCRIBER
  // transcriberKey: string
  // avatarId?: string
  // avatarQuality?: string
}

const Conversation: FC<Props> = ({
  clientSecret,
  sessionInfo,
  onConversationFinished,
  isTest = false,
  // interactionId,
  // onConversationFinished,
  handleChunk,
  // initVersion,
  // isTest,
  // setDuration,
  // azureKey,
  // heygenKey,
  // steps,
  // setSteps,
  // setRecapData,
  // avatar,
  // interruptionMode,
  lang,
  // availableLanguages,
  onChangeLang
  // transcriber,
  // transcriberKey,
  // avatarId,
  // avatarQuality
}) => {
  const startTimeRef = useRef<number>(Date.now())
  const recorderRef = useRef<IRecorder>(null)
  const heyGenRef = useRef<IHeyGenConnect>(null)
  const cameraVideoRef = useRef<HTMLVideoElement>(null)
  const isOverRef = useRef(false)
  const [orientation, setOrientation] = useState<'portrait' | 'landscape' | ''>(
    ''
  )
  const [controls, setControls] = useState<Proto.ControlT>({
    camera: isTest,
    mic: isTest
  })
  const controlsRef = useRef<ControlT>({ camera: false, mic: false })
  const [thereWasAnError, setThereWasAnError] = useState<string>()
  const [permissionsError, setPermissionsError] =
    useState<PermissionErrorType | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const [closed, setClosed] = useState<boolean>(false)
  const [permissionsChecked, setPermissionsChecked] = useState<boolean>(false)
  const controlPanelRef = useRef<IControlPanel>(null)
  const [userName, setUserName] = useState<string | null>(null)
  const [checkingPermissions, setCheckingPermissions] = useState(false)
  const [controlPanelVisible, setControlPanelVisible] = useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const avatarIsTalkingRef = useRef(false)
  const userIsTalkingRef = useRef(false)
  const nextQuestionRef = useRef<Phrase | null>(null)
  const userSpeechBufferRef = useRef('')
  const lastAppliedPhraseRef = useRef<Phrase>()
  const [permisssionsGranted, setPermissionsGranted] = useState(false)
  const silenceTimerRef = useRef<number>(0)
  // const facedetectorRef = useRef<mediapipe.FaceDetector | null>(null)
  const [screenshotProcessed, _setScreenshotProcessed] = useState(false)
  const screenshotProcessedRef = useRef(false)
  const [cameraOn, setCameraOn] = useState(false)
  const [avatarStarted, setAvatarStarted] = useState(false)
  const [showEmailInput, _setShowEmailInput] = useState(false)
  const showEmailInputRef = useRef(false)
  const [subtitlesOpenedByDefault, setSubtitlesOpenedByDefault] =
    useState(false)
  const replyGenerationRequestTimestampRef = useRef(0)
  const lastResponseSentToBackend = useRef<string>('')
  const [steps, setSteps] = useState<Phrase[]>(sessionInfo.steps)
  const latenciesRef = useRef<
    Record<string, { dgLatency: number; grLatency: number }>
  >({})
  const requestController = useRef<AbortController | null>(null)
  const transcriptIsVerifiedRef = useRef(false)

  jsLogger.log('CONVERSATION LANG:', lang)

  const processUrlParams = () => {
    const url = new URL(window.location.href)
    const cc = url.searchParams.get('cc')
    if (cc !== null) {
      setSubtitlesOpenedByDefault(true)
    }
  }

  useEffect(() => {
    jsLogger.info('CONVERSATION START')

    const back = getBackClient()
    back.transcriptVerifiedHandler = onTranscriptVerified

    processUrlParams()

    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_conversation_started', {
        version: PACKAGE.version
      })
    }
    function handleOrientationChange (event: { matches: any; media: any }) {
      const { matches } = event
      setOrientation(matches ? 'portrait' : 'landscape')
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)')
    setOrientation(mediaQueryPortrait.matches ? 'portrait' : 'landscape')

    mediaQueryPortrait.addEventListener('change', handleOrientationChange)

    return () => {
      mediaQueryPortrait.removeEventListener('change', handleOrientationChange)
      if (silenceTimerRef.current) {
        clearTimeout(silenceTimerRef.current)
      }
      const back = getBackClient()
      back.disconnect()
    }
  }, [])

  const setScreenshotProcessed = (v: boolean) => {
    _setScreenshotProcessed(true)
    screenshotProcessedRef.current = true
  }

  const setShowEmailInput = (v: boolean) => {
    _setShowEmailInput(v)
    showEmailInputRef.current = v
  }

  useEffect(() => {
    if (permissionsChecked) {
      startTimeRef.current = Date.now()
    }
  }, [permissionsChecked])

  useEffect(() => {
    const stream = streamRef.current as MediaStream
    if (stream) {
      const audioTrack = stream
        .getTracks()
        .find(track => track.kind === 'audio')
      if (audioTrack) {
        audioTrack.enabled = controls.mic
      }
      const videoTrack = stream
        .getTracks()
        .find(track => track.kind === 'video')
      if (videoTrack) {
        videoTrack.enabled = controls.camera
      }
    }
  }, [controls])

  const controlPanelSteps = useMemo(() => {
    return compact(
      map(steps, s => {
        return {
          id: s.id,
          text: s.text,
          isAvatar: s.isAvatar
        } as Step
      })
    )
  }, [steps])

  const onTranscriptVerified = (transcript: string) => {
    jsLogger.log('TRANSCRIPT IS VERIFIED', {
      transcript,
      transcriptIsVerified: transcriptIsVerifiedRef.current
    })
    if (!transcriptIsVerifiedRef.current) {
      heyGenRef.current?.cancel()
      onUserPhrase(transcript, 0, true, true)
      transcriptIsVerifiedRef.current = true
    }
  }

  const onHeyGenSessionStarted = () => {
    jsLogger.debug('HeyGenSessionStarted')
    jsLogger.log('camera and mic are enabled, saying initial phrase!!!!!!!')
    const heygenLatency = Date.now() - startTimeRef.current
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_heygen_started', {
        latency: heygenLatency,
        version: PACKAGE.version
      })
    }
    setAvatarStarted(true)
    const q = get(steps, 0)
    if (q.isAvatar) {
      lastAppliedPhraseRef.current = q
      setTimeout(() => heyGenRef.current?.say(q.text), 500)
    }
  }

  const onAvatarStartTalking = (latency: number) => {
    if (lastAppliedPhraseRef.current) {
      sendAvatarLatency(clientSecret, lastAppliedPhraseRef.current, latency)
    }
  }

  const checkMediaPermissions = async () => {
    if (isTest) {
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      return
    }
    try {
      jsLogger.debug('permissions_requested')
      setCheckingPermissions(true)
      await requestMediaPermissions()
      jsLogger.debug('permissions_granted')
      setCheckingPermissions(false)
      jsLogger.debug('no error with permissions')
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      heyGenRef.current?.play()
      controlsRef.current = { mic: true, camera: true }
      setControls({ mic: true, camera: true })
    } catch (error: any) {
      jsLogger.error('MediaOnboardingDialog: ', error)
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(PermissionErrorType.trackError)
      }
    }
  }

  const removeSilenceTimer = () => {
    jsLogger.log('remove silence timer')
    if (silenceTimerRef.current) {
      clearTimeout(silenceTimerRef.current)
    }
  }

  const setupSilenceTimer = () => {
    // if (sessionInfo.avatar === 'd-id') {
    //   jsLogger.log('silence timer is disabled for D-ID')
    //   return
    // }
    jsLogger.log('setupSilenceTimer --->')
    removeSilenceTimer()
    silenceTimerRef.current = window.setTimeout(
      () => onSilenceTimer(),
      SILENCE_DURATION
    )
  }

  const onAvatarPlayingFinished = () => {
    jsLogger.log('%conAvatarPlayingFinished', { isOver: isOverRef.current })
    avatarIsTalkingRef.current = false
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_avatar_ended_talking', {
        isOver: isOverRef.current,
        version: PACKAGE.version
      })
    }
    setupSilenceTimer()
    if (nextQuestionRef.current) {
      const q = nextQuestionRef.current
      jsLogger.log('found saved response', { q })
      sendApplyResponse(q)
      nextQuestionRef.current = null
      heyGenRef.current?.say(q.text)
      avatarIsTalkingRef.current = true
      // setIsRecording(true)
      // recorderRef.current?.start()
    } else if (isOverRef.current) {
      recorderRef.current?.stop()
      removeSilenceTimer()
      setTimeout(
        onConversationFinished,
        sessionInfo.avatar.provider === 'azure' ? 2000 : 0
      )
    } else if (!userIsTalkingRef.current) {
      jsLogger.debug('avatar ended speaking and the user is not speaking')
      onUserPhrase('', 0)
    }
  }

  const sendApplyResponse = async (q: Phrase) => {
    jsLogger.log('sendApplyResponse start', { q })
    const latencies = get(latenciesRef.current, q.id)
    const r = await applyResponse(clientSecret, q, latencies)
    lastAppliedPhraseRef.current = q
    console.log('sendApplyResponse end', { q, steps: r && r.steps })
    if (r && r.steps) {
      setSteps(r.steps)
    }
  }

  const addToBuffer = (t: string) => {
    const ar = [userSpeechBufferRef.current, t]
    const filteredAr = filter(ar, (v: string) => trim(v) !== '')
    userSpeechBufferRef.current = filteredAr.join(' ')
  }

  const onUserPhrase = async (
    speech: string,
    dgLatency: number,
    forceSend = false,
    isVerifiedTranscript = false
  ) => {
    jsLogger.log('onUserPhrase called', {
      speech,
      dgLatency,
      forceSend,
      avatarIsTalking: avatarIsTalkingRef.current,
      userSpeechBuffer: userSpeechBufferRef.current,
      transcriptIsVerified: transcriptIsVerifiedRef.current
    })

    const speechWithBuffer = trim(
      [trim(userSpeechBufferRef.current), trim(speech)].join(' ')
    )

    if (transcriptIsVerifiedRef.current) {
      jsLogger.log(
        'transcript is already verified and is processing, exit the function'
      )
      return
    }

    userIsTalkingRef.current = false

    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_on_phrase', {
        latency: dgLatency
      })
    }
    if (showEmailInputRef.current) {
      jsLogger.log('Email input is visible, skipping speech processing', {
        speech,
        buffer: userSpeechBufferRef.current,
        forceSend
      })
      return
    } else if (!screenshotProcessedRef.current && !forceSend) {
      jsLogger.log('Screenshot not processed, buffering speech', {
        speech,
        buffer: userSpeechBufferRef.current,
        forceSend
      })
      addToBuffer(speech)
      return
    } else if (
      avatarIsTalkingRef.current &&
      sessionInfo.interruptionMode === DBT.INTERRUPT.SMART
    ) {
      const needToInterrupt = await checkInterrupt(
        clientSecret,
        speechWithBuffer
      )
      jsLogger.log('Interruption check result', { needToInterrupt })
      if (!needToInterrupt) {
        jsLogger.log('Avatar is talking, buffering speech without interruption')
        addToBuffer(speech)
        return
      } else {
        jsLogger.log('Interrupting avatar speech')
        heyGenRef.current?.cancel()
      }
    } else if (avatarIsTalkingRef.current && !isVerifiedTranscript) {
      jsLogger.log('Avatar is talking, buffering speech without interruption')
      addToBuffer(speech)
      return
    }

    jsLogger.log('Processing speech', { buffer: userSpeechBufferRef.current })
    jsLogger.log('Speech with buffer', { speechWithBuffer })
    try {
      if (trim(speechWithBuffer) === '' && !forceSend) {
        jsLogger.log('Ignoring empty speechWithBuffer', {
          speechWithBuffer,
          forceSend
        })
        return null
      }
      userSpeechBufferRef.current = ''
      jsLogger.debug("Processing user's response", {
        userResponse: speechWithBuffer
      })
      const grStartTime = Date.now()
      replyGenerationRequestTimestampRef.current = grStartTime
      lastResponseSentToBackend.current = speechWithBuffer
      const controller = new AbortController()
      const signal = controller ? controller.signal : undefined
      if (requestController.current) {
        requestController.current.abort()
      }
      requestController.current = controller
      let res = await submitReply(clientSecret, speechWithBuffer, signal)
      requestController.current = null
      const grLatency = Date.now() - grStartTime
      jsLogger.log('Response generated', { res, latency: grLatency })
      const q = get(res, 'phrase') as Phrase | undefined
      jsLogger.log('Next question', { text: q?.text })
      const over = get(res, 'isOver', false)
      const flags = get(q, 'flags', [])
      jsLogger.log('Response flags', { flags })

      const showEmailInputDetected = flags.some(
        (f: { key: string; value: string }) =>
          f.key === 'emailInputHasBeenShown'
      )

      jsLogger.log('Response status', { over, showEmailInputDetected })

      if (!over && replyGenerationRequestTimestampRef.current !== grStartTime) {
        jsLogger.log('Request deprecated', {
          requestTimestamp: grStartTime,
          lastRequestTimestamp: replyGenerationRequestTimestampRef.current
        })
        return
      }

      if (res && has(res, 'error')) {
        const er = res as Proto.Error
        jsLogger.error(er)
      } else if (res && q) {
        latenciesRef.current[q.id] = { grLatency, dgLatency }
        res = res as Proto.SubmitReplyResponse
        res.steps && setSteps(res.steps)
        if (isOverRef.current) {
          jsLogger.log('Conversation over, skipping avatar reply')
        } else if (avatarIsTalkingRef.current && !isOverRef.current && over) {
          jsLogger.log('Conversation ending, buffering avatar phrase')
          nextQuestionRef.current = q
          if (sessionInfo.interruptionMode !== DBT.INTERRUPT.DISABLED) {
            heyGenRef.current?.cancel()
          }
        } else if (userIsTalkingRef.current && !over) {
          jsLogger.log('User talking, waiting for new phrase')
        } else if (trim(q.text) === '') {
          jsLogger.error('Backend returned empty phrase')
        } else if (avatarIsTalkingRef.current) {
          jsLogger.log('Avatar talking, skipping phrase')
        } else {
          jsLogger.debug('Avatar reply generated', {
            reply: get(q, 'text'),
            isOver: over,
            fullGenerationLatency: grLatency
          })
          sendApplyResponse(q)
          nextQuestionRef.current = null
          heyGenRef.current?.say(q.text)
          avatarIsTalkingRef.current = true
          if (showEmailInputDetected) {
            setShowEmailInput(true)
          }
        }

        // if (res.userName && isNil(userName)) {
        //   setUserName(res.userName)
        // }
        if (over) {
          isOverRef.current = over
        }
      }
    } catch (e) {
      jsLogger.error('Error in onUserPhrase', e)
    }
  }

  const generateNewPhrase = (userReply = '') => {
    onUserPhrase(userReply, 0, true)
  }

  const handleControlsChange = (v: ControlT) => {
    jsLogger.debug('handleControlsChange', v)
    if (!permissionsChecked) {
      checkMediaPermissions()
    }
    controlsRef.current = v
    setControls(v)
  }

  const onSilenceTimer = () => {
    jsLogger.log('onSilenceTimer')
    if (
      avatarIsTalkingRef.current ||
      showEmailInputRef.current ||
      !screenshotProcessedRef.current
    ) {
      jsLogger.log(
        'onSilenceTimer, avatar is talking, or email input is visible planing again',
        {
          avatarIsTalking: avatarIsTalkingRef.current,
          emailInputVisible: showEmailInputRef.current,
          screenshotProcessed: screenshotProcessedRef.current
        }
      )
      setupSilenceTimer()
    } else {
      jsLogger.log(
        'onSilenceTimer, avatar is not talking, need to generate avatar phrase'
      )
      generateNewPhrase()
    }
  }

  const onSpeech = async (v: string) => {
    jsLogger.log('Conversation onSpeech', {
      speech: v,
      buffer: userSpeechBufferRef.current,
      avatarIsTalking: avatarIsTalkingRef.current,
      isOver: isOverRef.current
    })
    jsLogger.log('onSpeech: Combining speech with buffer', {
      currentBuffer: userSpeechBufferRef.current,
      newSpeech: v
    })
    if (!isEmpty(trim(v)) && !transcriptIsVerifiedRef.current) {
      getBackClient().sendInterimTranscript(v)
    }
    const speechWithBuffer = trim(
      [trim(userSpeechBufferRef.current), trim(v)].join(' ')
    )
    jsLogger.log('onSpeech: Combined speech with buffer', {
      speechWithBuffer
    })
    // if (
    //   speechWithBuffer !== lastResponseSentToBackend.current &&
    //   speechWithBuffer !== ''
    // ) {
    //   jsLogger.log('onSpeech: New speech detected, generating new phrase', {
    //     lastResponseSentToBackend: lastResponseSentToBackend.current,
    //     newSpeech: v
    //   })
    //   // generateNewPhrase(v)
    // } else {
    //   jsLogger.log(
    //     'onSpeech: Speech matches last response, no new phrase generated'
    //   )
    // }
    controlPanelRef.current?.setSpeech(`${speechWithBuffer}`)
    setupSilenceTimer()
    if (avatarIsTalkingRef.current && !isOverRef.current) {
      if (sessionInfo.interruptionMode === DBT.INTERRUPT.REGULAR) {
        heyGenRef.current?.cancel()
      } else if (sessionInfo.interruptionMode === DBT.INTERRUPT.SMART) {
        const needToInterrupt = await checkInterrupt(
          clientSecret,
          speechWithBuffer
        )
        if (needToInterrupt) {
          heyGenRef.current?.cancel()
        }
      }
    }
  }

  const onCameraOn = () => {
    jsLogger.log('onCameraOn')
    setCameraOn(true)
  }

  const handleClose = () => {
    removeSilenceTimer()
    setClosed(true)
    const back = getBackClient()
    back.disconnect()
  }

  const handleVideoClick = () => {
    if (orientation === 'portrait' && !permissionsError && permissionsChecked) {
      controlPanelRef?.current?.click()
    }
  }

  const handleCameraChange = (v: string | null) => {
    setSelectedDeviceId(v)
    recorderRef.current?.cameraChange(v)
  }

  if (closed) {
    return <EndConversation />
  }

  if (permissionsError) {
    return <PermissionsHandler permissionsError={permissionsError} />
  }

  const avatarEnabled = true

  const renderButton = () => {
    // const title = permisssionsGranted
    //   ? getString('conversation_allow', lang)
    //   : 'Request permissions'
    const title = 'Request permissions'
    const onClick = requestPermissionsInternally
    return (
      <button
        className='h-16 bg-blue-400 text-white font-medium text-[16px] w-[260px] mt-7 rounded-[4px]'
        onClick={() => onClick()}
      >
        {title}
      </button>
    )
  }

  const requestPermissionsInternally = async () => {
    jsLogger.log('requestPermissionsInternally')
    try {
      jsLogger.debug('permissions_requested internally')
      await requestMediaPermissions()
      jsLogger.debug('permissions_granted internally')
      setPermissionsError(null)
      setPermissionsGranted(true)
      handleControlsChange({ mic: true, camera: true })
    } catch (error: any) {
      jsLogger.error('MediaOnboardingDialog: ', error)
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(PermissionErrorType.trackError)
      }
    }
  }

  const renderAvatar = () => {
    const avatar: Proto.SessionAvatar = sessionInfo.avatar
    if (avatar.provider === 'custom') {
      return (
        <CustomAvatarConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          isRecording={false}
          // handleChunk={isTest ? undefined : handleChunk}
          handleVideoClick={handleVideoClick}
          onSessionStarted={onHeyGenSessionStarted}
          permissionsGranted={permissionsChecked}
        />
      )
      // } else if (sessionInfo.avatar === 'd-id') {
      //   return (
      //     <DIDConnect
      //       ref={heyGenRef}
      //       onAvatarPlayingFinished={onAvatarPlayingFinished}
      //       thereWasAnError={thereWasAnError}
      //       setThereWasAnError={setThereWasAnError}
      //       onSessionStarted={onHeyGenSessionStarted}
      //       isRecording={false}
      //       permissionsGranted={permissionsChecked}
      //       handleChunk={isTest ? undefined : handleChunk}
      //       setDuration={setDuration}
      //       handleVideoClick={handleVideoClick}
      //       onAvatarStartTalking={onAvatarStartTalking}
      //     />
      //   )
    } else if (avatar.secret && avatar.provider === 'azure') {
      return (
        <AzureConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={() => null}
          handleVideoClick={handleVideoClick}
          azureKey={{
            token: avatar.secret.token,
            region: avatar.secret.region || ''
          }}
        />
      )
    } else if (avatar.secret) {
      return (
        <HeyGenConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={() => null}
          handleVideoClick={handleVideoClick}
          heygenKey={avatar.secret.token}
          onAvatarStartTalking={onAvatarStartTalking}
          lang={lang}
          avatarId={avatar.avatarId}
          avatarQuality={avatar.quality}
          avatarImageUrl={avatar.imageUrl}
        />
      )
    }
  }

  const renderControlPanel = () => {
    if (controlPanelVisible) {
      return (
        <ControlPanel
          ref={controlPanelRef}
          permissionsError={permissionsError}
          controls={controls}
          setControls={handleControlsChange}
          handleClose={handleClose}
          permissionsChecked={permissionsChecked}
          steps={controlPanelSteps}
          userName={userName}
          isRecording={true}
          orientation={orientation}
          devices={devices}
          selectedDeviceId={selectedDeviceId}
          setSelectedDeviceId={handleCameraChange}
          availableLanguages={sessionInfo.availableLanguages}
          lang={lang}
          onChangeLang={onChangeLang}
          subtitlesOpenedByDefault={subtitlesOpenedByDefault}
        />
      )
    }
  }

  const renderScreeshotsProcessor = () => {
    if (!screenshotProcessed) {
      return (
        <CapturePhoto
          videoRef={cameraVideoRef}
          onComplete={() => {
            jsLogger.log('CapturePhoto, ON COMPLETE')
            setScreenshotProcessed(true)
            generateNewPhrase()
          }}
          interactionId={clientSecret}
          sayLookAtCamera={() => {
            jsLogger.log('CapturePhoto, sayLookAtCamera')
            generateNewPhrase()
          }}
          cameraOn={cameraOn && avatarStarted}
        />
      )
    }
  }

  const onNotNowClick = async () => {
    setShowEmailInput(false)
    await dbSkipEmail(clientSecret)
    generateNewPhrase()
  }

  const onConfirmClick = async (email: string) => {
    jsLogger.log('onConfirmClick', { email })
    setShowEmailInput(false)
    await dbSendUserEmail(clientSecret, email)
    generateNewPhrase(email)
  }

  const renderEmailInput = () => {
    if (showEmailInput) {
      return (
        <EmailInput
          onNotNowClick={onNotNowClick}
          onConfirmClick={onConfirmClick}
          locale={lang}
        />
      )
    }
  }

  return (
    <PageContainer version={PACKAGE.version}>
      {renderScreeshotsProcessor()}
      {avatarEnabled && renderAvatar()}
      {isTest && (
        <RecorderDummy
          dgKey={sessionInfo.transcriberKey}
          onPhrase={onUserPhrase}
        />
      )}
      {controlPanelVisible && !isTest && (
        <div
          className={`flex-1 bg-slate-100 rounded-md absolute top-[3rem] left-5 w-28 h-44`}
        >
          <Recorder
            ref={recorderRef}
            handleChunk={isTest ? undefined : handleChunk}
            onPhrase={onUserPhrase}
            isActive={true}
            onSpeech={onSpeech}
            cameraVideoRef={cameraVideoRef}
            controls={controls}
            setControls={handleControlsChange}
            setThereWasAnError={setThereWasAnError}
            permissionsError={permissionsError}
            streamRef={streamRef}
            interactionId={clientSecret}
            transcriber={sessionInfo.transcriber}
            transcriberKey={sessionInfo.transcriberKey}
            setDevices={setDevices}
            setSelectedDeviceId={setSelectedDeviceId}
            onStartTalking={() => (userIsTalkingRef.current = true)}
            onCameraOn={onCameraOn}
            lang={lang}
          />
        </div>
      )}

      {permissionsError === PermissionErrorType.userDenied && <FixPermission />}
      {renderControlPanel()}
      {!controlPanelVisible && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center'>
          <div className='z-10 absolute top-20 left-4 text-gray-300'>
            <div>
              <div style={{ height: '50px', width: '50px' }}>
                <DotLottieReact
                  src='https://lottie.host/1ba91606-15d0-4f27-9f17-095c58dfb5ac/IivUjwcaOY.json'
                  autoplay
                  loop
                  backgroundColor='transparent'
                  speed={1}
                  style={{ width: '100%', height: '100%' }}
                  mode='forward'
                />
              </div>
            </div>
            <span className='text-[10px] pt-1'>
              <i>{getString('conversation_volume_up', lang)}</i>
            </span>
          </div>

          {checkingPermissions && (
            <div className='z-10 text-[24px] text-white font-semibold flex flex-col items-center'>
              <span className='drop-shadow-[1px_1px_1px_rgba(0,0,0,1)]'>
                <i>{getString('conversation_initializing', lang)}</i>
              </span>
              <div className='h-1 w-[187px] bg-white mt-[23px]'>
                <div className='h-1 w-[106px] bg-teal-600' />
              </div>
            </div>
          )}

          {!checkingPermissions && (
            <div className='z-10 w-[300px] flex flex-col items-center'>
              <span className='font-semibold text-[24px] text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] text-center leading-8'>
                {getString('conversation_about_to_chat', lang)}
              </span>
              <div className='w-full bg-white mt-10 rounded-xl flex flex-col items-center pt-7 pb-4'>
                <span className='text-[20px] font-semibold text-center leading-7 px-4'>
                  {getString('conversation_request_permissions', lang)}
                </span>
                {renderButton()}
              </div>
            </div>
          )}
        </div>
      )}
      {renderEmailInput()}
    </PageContainer>
  )
}

export default Conversation
