import { useEffect, useState, useRef } from 'react'
import jsLogger from 'js-logger'
import * as Sentry from '@sentry/react'
import has from 'lodash/has'
import get from 'lodash/get'
import {
  conversationStart,
  getNavigatorLanguage,
  sessionSetLang
} from 'controllers/main'
import Conversation from 'pages/Conversation'
import * as Proto from 'types/proto'
import ErrorPage from 'pages/ErrorPage'
import FinalPage from 'pages/FinalPage'
import { BackClient, getBackClient } from 'controllers/back'

const SESSION_TIMEOUT = 600000

const App = () => {
  // const [initializing, setInitializing] = useState(true)
  const [error, setError] = useState<string>()
  const [sessionInfo, setSessionInfo] = useState<Proto.SessionStartResponse>()
  const [isComplete, setIsComplete] = useState(false)
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [lang, setLang] = useState('en')
  const [sessionTimedout, setSessionTimedout] = useState(false)
  const backClientRef = useRef<BackClient>(getBackClient())

  useEffect(() => {
    let locale
    const windowUrl = new URL(window.location.href)
    const urlLang = windowUrl.searchParams.get('lang')
    if (urlLang) {
      locale = urlLang
    } else {
      const browserLang = getNavigatorLanguage().slice(0, 2).toLowerCase()
      locale = browserLang
    }
    if (locale) {
      setLang(locale)
    }
    setTimeout(() => setSessionTimedout(true), SESSION_TIMEOUT)
  }, [])

  useEffect(() => {
    const url = new URL(window.location.href)
    const cs = url.searchParams.get('cs')
    setClientSecret(cs)

    jsLogger.debug('session start', { url, scExists: cs !== null })

    const run = async () => {
      if (cs) {
        jsLogger.debug('conversationStart api call start', cs)
        const apires: Proto.SessionStartResponse | Proto.Error =
          await conversationStart(cs)
        jsLogger.debug('conversationStart api call end', { apires })
        if (apires && has(apires, 'error')) {
          const er = apires as Proto.Error
          setError(er.error)
          window.top &&
            window.top.postMessage(
              JSON.stringify({ type: 'ERROR', message: er.error }),
              '*'
            )
        } else {
          const sInfo = apires as Proto.SessionStartResponse
          backClientRef.current.interactionStarted(sInfo.sessionId, cs, false)
          setSessionInfo(sInfo)
        }

        Sentry.setContext('session', {
          secret: cs
        })
      }
    }
    run()
  }, [])

  const onConversationFinished = () => {
    if (sessionInfo) {
      jsLogger.debug('conversation finished')
    }
    setIsComplete(true)
    window.top &&
      window.top.postMessage(
        JSON.stringify({ type: 'EVENT', message: 'SESSION_FINISHED' }),
        '*'
      )
    // setRecordStopped(true)
  }

  const onChangeLang = (l: string) => {
    jsLogger.debug('onChangeLang', { lang: l })
    if (clientSecret) {
      sessionSetLang(clientSecret, l)
      setLang(l)
    }
  }

  const handleChunk = async (
    chunk: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => {
    // chunksRef.current[role].push(chunk)
    const videoSavingDisabled = false
    if (!videoSavingDisabled) {
      backClientRef.current.sendVideoChunk(chunk, mimeType, role)
    }
  }

  const renderContent = () => {
    if ((!clientSecret || !sessionInfo) && !error) {
      return null
      // } else if (recordStopped && !isComplete) {
      //   return <Loading waitingForUpload />
    } else if (isComplete) {
      return <FinalPage imageUrl={get(sessionInfo?.avatar, 'imageUrl')} />
    } else if (error) {
      return <ErrorPage error={error} />
    } else if (sessionInfo && clientSecret) {
      return (
        <Conversation
          clientSecret={clientSecret}
          sessionInfo={sessionInfo}
          onConversationFinished={onConversationFinished}
          lang={lang}
          onChangeLang={(l: string) => onChangeLang(l)}
          handleChunk={handleChunk}
        />
      )
    }
  }

  return (
    <div className='w-full h-full relative flex justify-center items-center no-scrollbar'>
      {renderContent()}
    </div>
  )
}

export default App
