import EventEmitter from 'events'
import { Socket, io } from 'socket.io-client'
import {
  ServerToClientEvents,
  ClientToServerEvents,
  ILog,
  SessionStarted
} from 'types/back'
import config from 'src/config'

export enum ConnectionState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

export const enum BackEvent {
  Open = 'open',
  Close = 'close',
  Error = 'error'
}

export class BackClient extends EventEmitter {
  private _socket: Socket<ServerToClientEvents, ClientToServerEvents> | null =
    null
  private _sessionId: string | null = null
  private _clientSecret: string | null = null
  private _noVideo = false
  private _logsBuffer: ILog[] = []
  private _transcriptVerifiedHandler: (v: string) => void = () => null

  constructor () {
    super()
    this.connect()
  }

  public set transcriptVerifiedHandler (f: (v: string) => void) {
    this._transcriptVerifiedHandler = f
  }

  private connect = () => {
    console.log('Back: connect')
    if (this._socket) {
      this._socket.disconnect()
      this._socket = null
    }

    const isLocalhost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'

    const serverUrl = isLocalhost
      ? 'http://localhost:3001'
      : config.socketServerUrl
    this._socket = io(serverUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket']
      // forceBase64: true
    })

    this._bindSocketEvents()
  }

  private _bindSocketEvents (): void {
    if (this._socket) {
      this._socket.on('connect', () => {
        console.log('socket connected')
        this.emit(BackEvent.Open, this)
        this._flushLogs()
        // const isLocalhost =
        //   window.location.hostname === 'localhost' ||
        //   window.location.hostname === '127.0.0.1'
        // if (this._socket ) {
        //   this._socket.emit(
        //     'interactionStarted',
        //     this._interactionId,
        //     isLocalhost,
        //     this._noVideo
        //   )
        // }
      })

      this._socket.on('transcriptVerified', (transcript: string) => {
        this._transcriptVerifiedHandler(transcript)
      })

      this._socket.on('disconnect', () => {
        console.log('BACK DISCONNECTED')
      })
    }
  }

  private _flushLogs = () => {
    if (this._socket && this._sessionId && this._logsBuffer.length > 0) {
      console.log('flushing logs', this._logsBuffer.length)
      this._socket.emit('log', this._sessionId, this._logsBuffer)
      this._logsBuffer = []
    }
  }

  public sendLog = (messages: any[], context: object) => {
    const l: ILog = {
      messages,
      context,
      timestamp: Date.now()
    }

    if (this._socket && this._sessionId && this._clientSecret) {
      this._socket.emit('sessionLog', this._sessionId, this._clientSecret, [l])
    } else {
      this._logsBuffer.push(l)
    }
  }

  public sendInterimTranscript = (transcript: string) => {
    if (this._socket && this._sessionId && this._clientSecret) {
      this._socket.emit(
        'sessionInterimTranscript',
        this._sessionId,
        this._clientSecret,
        transcript
      )
    }
  }

  public sendVideoChunk = (
    chunk: Blob,
    mimeType: string,
    role: 'avatar' | 'user'
  ) => {
    if (this._socket && this._sessionId && this._clientSecret) {
      // console.log('sendVideoChunk', chunk.size, mimeType, role)
      try {
        this._socket.emit(
          'sessionVideoChunk',
          this._sessionId,
          this._clientSecret,
          chunk,
          mimeType,
          role
        )
      } catch (e) {
        console.error('send video chunk error', e)
      }
    }
  }

  public interactionStarted = (
    sessionId: string,
    clientSecret: string,
    noVideo: boolean
  ) => {
    this._sessionId = sessionId
    this._clientSecret = clientSecret
    this._noVideo = noVideo
    const isLocalhost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    if (this._socket) {
      const params: SessionStarted = {
        sessionId,
        clientSecret,
        isLocalhost,
        noVideo
      }
      this._socket.emit('sessionStarted', params)
    }
    this._flushLogs()
  }

  public disconnect (): void {
    if (this._socket) {
      if (this._sessionId && this._clientSecret) {
        this._socket.emit('sessionEnd', this._sessionId, this._clientSecret)
      }
      this._socket.disconnect()
      this._socket.close()
    }
  }
}

let cl: BackClient | null = null

export const getBackClient = () => {
  if (cl) {
    return cl
  } else {
    console.log('createing new BackClient')
    cl = new BackClient()
    return cl
  }
}
